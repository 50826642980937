<template>
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <b-tabs content-class="mt-3" pills>
          <b-tab :title="$t('COMMON.MAIN')" active>
            <b-card-body>
              <b-form-group
                id="field-name"
                label-cols-lg="2"
                :label="$t('COMMON.NAME')"
                label-for="field-name"
              >
                <b-form-input
                  id="field-name-input"
                  v-model="dto.name"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-description"
                label-cols-lg="2"
                :label="$t('COMMON.DESCRIPTION')"
                label-for="field-description-input"
              >
                <b-form-input
                  id="field-description-input"
                  v-model="dto.description"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-value"
                label-cols-lg="2"
                :label="$t('DEVICE.VALUE')"
                label-for="field-value-input"
              >
                <b-form-input
                  id="field-value-input"
                  v-model="dto.value"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-visible"
                label-cols-lg="2"
                :label="$t('PROPERTIES.VISIBLE_FOR_ADMIN')"
                label-for="field-visible-input"
              >
                <b-form-checkbox
                  id="checkbox-approved-input"
                  v-model="dto.visible"
                  name="checkbox-visible"
                  class="pt-2"
                  switch
                  size="lg"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-card-body>
          </b-tab>
        </b-tabs>

        <div style="float: right">
          <b-button variant="success" v-on:click="onSaveClicked">{{
            $t("COMMON.SAVE")
          }}</b-button>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { API_REQUEST } from "@/core/services/store/api.module";
//import Common from "../../common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "property",
  components: {},
  data() {
    return {
      save: {
        resource: "/platform/api/property/save",
        requestType: "POST",
        requestParams: {},
      },

      dto: {
        id: null,
        name: null,
        description: null,
        value: null,
        visible: false,
      },
      createNewStr: this.$t("PROPERTIES.CREATE_NEW_PROPERTY"),
      editStr: this.$t("PROPERTIES.EDIT_PROPERTY"),

      showOverlay: true,
    };
  },

  mounted() {
    let title = !this.$route.params.id ? this.createNewStr : this.editStr;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.PROPERTIES"), route: "/properties" },
      { title: title },
    ]);
  },

  created: async function () {
    if (this.$route.params.id != null) {
      await this.$store
        .dispatch(API_REQUEST, {
          resource: "/platform/api/property/" + this.$route.params.id,
          requestType: "GET",
        })
        .then((response) => (this.dto = response))
        .catch(this.onError);
    }

    this.showOverlay = false;
  },

  methods: {
    onSaveClicked: function () {
      const request = this.save;
      request.dto = this.dto;

      this.$store
        .dispatch(API_REQUEST, request)
        .then((response) => {
          this.dto.id = response.id;

          this.$bvToast.toast(this.$t("COMMON.SAVED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch(this.onError);
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },
  },
};
</script>
